<script>
/* eslint-disable */
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';

import {required, helpers} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import Multiselect from '@vueform/multiselect';
import Layout from '../../layouts/main';

import {userGridData} from './data-user';

/**
 * Contacts-grid component
 */
export default {
  setup() {
    return {v$: useVuelidate()};
  },
  page: {
    title: 'Contact Users Grid',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {Layout, PageHeader, Multiselect},
  data() {
    return {
      userGridData,
      title: 'Users Grid',
      items: [
        {
          text: 'Contacts',
          href: '/',
        },
        {
          text: 'Users Grid',
          active: true,
        },
      ],
      showModal: false,
      submitted: false,
      users: {
        name: '',
        designation: '',
        email: '',
      },
      value1: null,
      options: [
        'Photoshop',
        'illustrator',
        'Html',
        'Css',
        'Php',
        'Java',
        'Python',
        'Ruby',
      ],
    };
  },

  validations: {
    users: {
      name: {
        required: helpers.withMessage('Name is required', required),
      },
      designation: {
        required: helpers.withMessage('Designation is required', required),
      },
      email: {required: helpers.withMessage('Email is required', required)},
    },
  },
  methods: {
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.userGridData.push({
        id: this.userGridData.length + 1,
        name: this.users.name,
        designation: this.users.designation,
        projects: this.value1,
        email: this.users.email,
      });
      this.showModal = false;
      this.users = {};
      this.value1 = [];

      this.submitted = false;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="d-flex">
        <button class="btn btn-success ms-auto mb-2" @click="showModal = true">
          Add Customer
        </button>
      </div>
    </div>
    <b-modal v-model="showModal" hide-footer title="Add Customer">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Event Name</label>
              <input
                id="name"
                v-model="users.name"
                :class="{
                  'is-invalid': submitted && v$.users.name.$error,
                }"
                class="form-control"
                placeholder="Insert name"
                type="text"
              />
              <div
                v-if="submitted && v$.users.name.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.users.name.required.$message">{{
                    v$.users.name.required.$message
                  }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="name">Designation</label>
              <input
                id="designation"
                v-model="users.designation"
                :class="{
                  'is-invalid': submitted && v$.users.designation.$error,
                }"
                class="form-control"
                placeholder="Insert designation"
                type="text"
              />
              <div
                v-if="submitted && v$.users.designation.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.users.designation.required.$message">{{
                    v$.users.designation.required.$message
                  }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="mb-3">
              <label for="email">Email</label>
              <input
                id="email"
                v-model="users.email"
                :class="{
                  'is-invalid': submitted && v$.users.email.$error,
                }"
                class="form-control"
                placeholder="Insert email"
                type="email"
              />
              <div
                v-if="submitted && v$.users.email.$error"
                class="invalid-feedback"
              >
                <span v-if="v$.users.email.required.$message">{{
                    v$.users.email.required.$message
                  }}</span>
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="projects">Projects</label>
            <multiselect
              v-model="value1"
              :multiple="true"
              :options="options"
            ></multiselect>
          </div>
        </div>
        <div class="text-end mt-3">
          <b-button variant="light" @click="showModal = false">Close</b-button>
          <b-button class="ms-1" type="submit" variant="success"
          >Create event
          </b-button
          >
        </div>
      </form>
    </b-modal>
    <div class="row">
      <div
        v-for="user in userGridData"
        :key="user.id"
        class="col-xl-3 col-sm-6"
      >
        <div class="card text-center">
          <div class="card-body">
            <div v-if="!user.image" class="avatar-sm mx-auto mb-4">
              <span
                class="
                  avatar-title
                  rounded-circle
                  bg-soft bg-primary
                  text-primary
                  font-size-16
                "
              >{{ user.name.charAt(0) }}</span
              >
            </div>
            <div v-if="user.image" class="mb-4">
              <img
                :src="`${user.image}`"
                alt
                class="rounded-circle avatar-sm"
              />
            </div>
            <h5 class="font-size-15 mb-1">
              <a class="text-dark" href="javascript: void(0);">{{
                  user.name
                }}</a>
            </h5>
            <p class="text-muted">{{ user.designation }}</p>

            <div>
              <a
                class="badge bg-primary font-size-11 m-1"
                href="javascript: void(0);"
              >{{ user.projects[0] }}</a
              >
              <a
                class="badge bg-primary font-size-11 m-1"
                href="javascript: void(0);"
              >{{ user.projects[1] }}</a
              >
              <a
                class="badge bg-primary font-size-11 m-1"
                href="javascript: void(0);"
              >{{ user.projects[2] }}</a
              >
            </div>
          </div>
          <div class="card-footer bg-transparent border-top">
            <div class="contact-links d-flex font-size-20">
              <div class="flex-fill">
                <a
                  v-b-tooltip.hover.top
                  href="javascript: void(0);"
                  title="Message"
                >
                  <i class="bx bx-message-square-dots"></i>
                </a>
              </div>
              <div class="flex-fill">
                <a
                  v-b-tooltip.hover.top
                  href="javascript: void(0);"
                  title="Projects"
                >
                  <i class="bx bx-pie-chart-alt"></i>
                </a>
              </div>
              <div class="flex-fill">
                <a
                  v-b-tooltip.hover.top
                  href="javascript: void(0);"
                  title="Profile"
                >
                  <i class="bx bx-user-circle"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-12">
        <div class="text-center my-3">
          <a class="text-success" href="javascript:void(0);">
            <i class="bx bx-hourglass bx-spin me-2"></i> Load more
          </a>
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>
