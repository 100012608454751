/* eslint-disable */
const userGridData = [
  {
    id: 1,
    name: 'David McHenry',
    designation: 'UI/UX Designer',
    projects: ['Photoshop', 'illustrator'],
    email: 'david@skote.com',
  },
  {
    id: 2,
    image: require('@/assets/images/users/avatar-2.jpg'),
    name: 'Frank Kirk',
    designation: 'Frontend Developer',
    projects: ['Html', 'Css', '2 + more'],
    email: 'frank@skote.com',
  },
  {
    id: 3,
    image: require('@/assets/images/users/avatar-3.jpg'),
    name: 'Rafael Morales',
    designation: 'Backend Developer',
    projects: ['Php', 'Java', 'Python'],
    email: 'Rafael@skote.com',
  },
  {
    id: 4,
    name: 'Mark Ellison',
    designation: 'Full Stack Developer',
    projects: ['Ruby', 'Php', '2 + more'],
    email: 'mark@skote.com',
  },
  {
    id: 5,
    image: require('@/assets/images/users/avatar-4.jpg'),
    name: 'Minnie Walter',
    designation: 'Frontend Developer',
    projects: ['Html', 'Css', '2 + more'],
    email: 'minnie@skote.com',
  },
  {
    id: 6,
    image: require('@/assets/images/users/avatar-5.jpg'),
    name: 'Shirley Smith',
    designation: 'UI/UX Designer',
    projects: ['Photoshop', 'illustrator'],
    email: 'shirley@skote.com',
  },
  {
    id: 7,
    name: 'John Santiago',
    designation: 'Full Stack Developer',
    projects: ['Ruby', 'Php', '2 + more'],
    email: 'john@skote.com',
  },
  {
    id: 8,
    image: require('@/assets/images/users/avatar-5.jpg'),
    name: 'Colin Melton',
    designation: 'Backend Developer',
    projects: ['Php', 'Java', 'Python'],
    email: 'colin@skote.com',
  },
];

export {userGridData};
